import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link, graphql,useStaticQuery } from "gatsby"
import RecipesList from "../components/RecipesList"
import SEO from '../components/SEO'

const query = graphql`
  {
    allContentfulRecipe(
      sort: {fields: title, order: ASC}
      filter: {featured: {eq: true}}
    ) {
      nodes {
        image {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        id
        title
        cookTime
        prepTime
        content {
          id
        }
      }
      totalCount
    }
  }
`

const About = () => {
  const data = useStaticQuery(query);
  const recipes = data.allContentfulRecipe.nodes;
  console.log(recipes);
  return (
    <Layout>
      <SEO title="About Page"/>
      <main className="page">
        <section className="about-page">
          <article>
            <h2>About Us</h2>
            <p>
              Leggings humblebrag listicle hexagon. Whatever woke cray direct
              trade kitsch, thundercats iceland leggings bespoke la croix four
              loko. Chicharrones beard shoreditch, vape schlitz butcher listicle
              fam blog knausgaard. Squid distillery cloud bread disrupt small
              batch.
            </p>
            <Link to="/contact" className="btn">
              Contact Us
            </Link>
          </article>
          <StaticImage
            src="../assets/images/about.jpeg"
            alt="About Us"
            className="about-img"
          />
        </section>
        <section className="featured-recipes">
          <h5>Look at This Awesomesauce</h5>
          <RecipesList recipes={recipes} />
        </section>
      </main>
    </Layout>
  )
}



export default About
